import Modal from '../_modal';
import {withRouter} from 'react-router-dom';
import Wizard from './_wizard';
import Step from './_wizard_step';
import AddCards from './_wizard_add_cards';
import InitialStep from './_wizard_initial_step';
import {dynamicCardGroupsForSelector, newBoardDefaultGroupItems, templateGroupsForSelector} from '../../modules/new_board_wizard_utils';
import {rivalsList} from '../../modules/api/rivals';

class NewBoardWizard extends React.PureComponent {

  static contextTypes = {
    api: PropTypes.object.isRequired
  };

  static propTypes = {
    activeGroup: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onCreateBoard: PropTypes.func.isRequired,
    isBoardReady: PropTypes.bool.isRequired,
    noDataAutomatedCards: PropTypes.array.isRequired,
    newBoardData: PropTypes.object,
    onCreateAutomatedCards: PropTypes.func.isRequired
  };

  static defaultProps = {
    activeGroup: null,
    newBoardData: {},
    isBoardReady: false,
    noDataAutomatedCards: [],
    onCreateAutomatedCards() {},
    onClose() {},
    onCreateBoard() {}
  };

  state = {
    templateGroups: [],
    rivals: [],
    selectedAutomatedCards: []
  };

  componentDidMount() {
    this.mounted = true;

    const rivalOptions = {
      order: 'alpha'
    };

    rivalsList({rivalOptions, code: 'NewBoardWizard.componentDidMount'}).then(rivals => {
      this.setState({rivals});
    });

    templateGroupsForSelector()
      .then(templateGroups => {
        if(!this.mounted) {
          return;
        }

        this.setState({templateGroups});
      }).catch(err => console.log(err));
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  mounted = false;

  initialValues = () => {
    const {activeGroup} = this.props;

    const initialValues = {};

    if(activeGroup) {
      initialValues.groups = [activeGroup];
    }

    return initialValues;
  };

  render() {
    const {onClose, onCreateBoard, isBoardReady, noDataAutomatedCards, newBoardData, onCreateAutomatedCards} = this.props;
    const {templateGroups, rivals, selectedAutomatedCards} = this.state;

    const initialValues = this.initialValues();
    const automatedCardGroups = dynamicCardGroupsForSelector(noDataAutomatedCards);
    
    const nextValues = isBoardReady ? {automatedCards: newBoardDefaultGroupItems(automatedCardGroups), profileId: newBoardData?.profile?.id || null} : null;

    const onAddCardsChange = values => {
      this.setState({selectedAutomatedCards: values});
    };

    return (
      <Modal header={false} padded={false} basic={true} onClose={onClose} closeOnOutsideClick={false}>
        <Wizard
          onSubmit={onCreateBoard}
          onCreateAutomatedCards={onCreateAutomatedCards}
          onClose={onClose}
          initialValues={initialValues}
          nextValues={nextValues}
          isBoardReady={isBoardReady}
          selectedAutomatedCards={selectedAutomatedCards}
          rivals={rivals}>
          <Step
            title="Let's build your new Klue Board"
            showError={true}>
            <InitialStep
              rivals={rivals}
              templateGroups={templateGroups} />
          </Step>
          {(newBoardData?.category === 'company' || newBoardData?.category === 'product') && <Step
            title="Success - Board Created!"
            showError={true}>
            <AddCards
              newBoardData={newBoardData}
              automatedCardGroups={automatedCardGroups}
              onAddCardsChange={onAddCardsChange} />
          </Step>}

        </Wizard>

      </Modal>
    );
  }

}

export default withRouter(NewBoardWizard);
