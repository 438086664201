import SearchSuggester from './_search_suggester';
import CompanyLogo from './_company_logo';
import Icon from './_icon';

import {rivalsList} from '../modules/api/rivals';
import {redirectToV2} from '../modules/route_utils';
import {userIsKluebot, userIsAdmin, userCanCurate} from '../modules/roles_utils';
import {sendMessageToExtensionBackground} from '../modules/ext_utils';
import {dig, isValidId} from '../modules/utils';
import {uiMaxResults} from '../modules/constants/ui';

import classNames from 'classnames';
import {debounce} from 'lodash';
import {Link, withRouter} from 'react-router-dom';
import KlueLogo from './_klue_logo';
import NavigationDropdown from './_navigation_dropdown';
import NavigationDropdownCombined from './_navigation_dropdown_combined';

class Navigation extends React.Component {

  static contextTypes = {
    api: PropTypes.object.isRequired,
    appData: PropTypes.object.isRequired,
    utils: PropTypes.object.isRequired
  };

  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    rivalGroups: PropTypes.arrayOf(PropTypes.object),
    notificationCounts: PropTypes.object,
    defaultGroupId: PropTypes.number
  };

  static defaultProps = {
    history: {},
    location: {},
    rivalGroups: [],
    notificationCounts: null,
    defaultGroupId: -1      // -1 = recently viewed profiles; 0 = "ungrouped rivals" (not displayed in navigation)
  };

  state = {
    ...Navigation.initialState,
    ...{
      // keep activeGroupId out of any resetState() operations (don't want to lose currently active group)
      // NOTE: activeGroupId = -1 defaults open grouping to "recently viewed" if present
      activeGroupId: -1,
      rivals: [],
      filteredRivals: [],
      currentPreviewBattlecards: null,
      isInitialRivalLoad: true
    }
  };

  componentDidMount() {
    // DEBUG
    console.log('Navigation.componentDidMount: props: %o, appData: %o', this.props, this.context.appData);
    this._isMounted = true;

    const {defaultGroupId} = this.props;
    const {activeGroupId} = this.state;

    if(defaultGroupId > 0) {
      // initially populate activeGroupId from defaultGroupId if one is set (ignore changes later)
      // NOTE: defaultGroupId === -1 for "all groups" view
      this.setState({activeGroupId: defaultGroupId}, () => this.getRivalsForGroup(activeGroupId));
    }
    else {
      this.getRivalsForGroup(activeGroupId);
    }

    window.addEventListener('keypress', this.handleDocumentKeyPress, false);  // IE9+
    window.addEventListener('keydown', this.handleDocumentKeyDown, false);    // IE9+
  }

  componentDidUpdate(prevProps) {
    const {location} = this.props;
    const {isInitialRivalLoad} = this.state;
    const {utils} = this.context;

    const queryParams = new URLSearchParams(location.search);
    const previewGroup = queryParams.get('previewing');
    const previousQueryParams = new URLSearchParams(prevProps.location.search);

    if(queryParams.has('previewing')) {
      const visibilityGroup = parseInt(previewGroup, 10);
      const previousPreviewGroup = previousQueryParams.get('previewing');

      const initialRivalLoad = isInitialRivalLoad && utils.rival;
      const queryChanged = location.pathname.includes('profile') && previewGroup !== previousPreviewGroup;

      if(initialRivalLoad || queryChanged) {
        this.setPreviewBattleCard(visibilityGroup, isInitialRivalLoad);
      }
    }
    else if(previousQueryParams.has('previewing')) {
      this.setPreviewBattleCard(null);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.handleDocumentKeyPress, false);
    window.removeEventListener('keydown', this.handleDocumentKeyDown, false);

    this._isMounted = false;
  }

  static initialState = {
    showSettingsSidebar: false,
    showQuickSwitcher: false,
    showBattlecardsDropdown: false,
    companyFilter: '',
    rivals: [],
    filteredRivals: []
  };

  // minimum # of recent profiles required to hide "all companies" default listing
  static MIN_RECENT_PROFILES = 3;

  _currentFilter = '';

  resetState = () => {
    this.setState({...this.state, ...Navigation.initialState}, () => {
      if(this.refs.companyFilterInput) {
        this.refs.companyFilterInput.value = '';
      }

      this.toggleIntercomButton(true);
      this.toggleBodyScrolling(true);
    });
  };

  setPreviewBattleCard = (visibilityGroupId, isInitialRivalLoad) => {
    const notPreviewing = visibilityGroupId === null || !isValidId(visibilityGroupId, true);

    if(notPreviewing) {
      return this.setState({
        currentPreviewBattlecards: null
      });
    }

    this.setState({
      isInitialRivalLoad: isInitialRivalLoad ? false : isInitialRivalLoad
    }, () => {
      const {utils, api: {battlecardsGet}} = this.context;

      battlecardsGet({
        profileId: utils.rival.profile.id,
        visibilityGroupId,
        callback: battlecards => {
          this.setState({
            currentPreviewBattlecards: battlecards || [],
            isInitialRivalLoad: isInitialRivalLoad ? false : isInitialRivalLoad
          });
        }
      });
    });
  };

  resetScrollPosition = event => {
    this.resetState();

    if(event && (event.metaKey || (event.nativeEvent.which !== 1))) {
      // if a non-left mouse click or CMD+left click is the trigger, skip scroll reset (likely opening in a new window)
      return;
    }

    // reset document scroll (otherwise chrome hackjacks on page change)
    setTimeout(window.scrollTo.bind(null, 0, 0), 1);
  };

  gotoPageExternal = (pathOrUrl, openInSameTab, event) => {
    if(event) {
      event.preventDefault();
    }

    if(openInSameTab === true) {
      window.location = pathOrUrl;
    }
    else {
      const win = window.open(pathOrUrl, '_blank');

      win.focus();
    }
  };

  gotoPagePush = (appPath, event) => {
    if(event) {
      event.preventDefault();
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
    }

    this.props.history.push(appPath);

    this.resetScrollPosition();
  };

  getBattlecardPath = profileId => {
    if(profileId <= 0) {
      return;
    }

    const pathName = this.context.utils.getCurrentLocation();

    // set the card filter search if one is currently active
    const {location: {search}} = this.props;
    const queryParams = new URLSearchParams(search);
    const cardFilter = queryParams.get('cardFilter');
    const cardFilterSearch = cardFilter ? `?cardFilter=${cardFilter}` : '';
    const isEditSection = /edit(\/\d+)?\/?$/.test(pathName);

    return `/profile/${profileId}/battlecard/${isEditSection ? 'edit' : 'view'}${cardFilterSearch}`;
  };

  // TODO: replace this with axios delete() method and ditch the dummy form
  gotoPathWithDeleteMethod = (path, event) => {
    if(event) {
      event.preventDefault();
    }

    // as required by rails, /account/signout & /impersonate/revert require a POST request with the DELETE param
    const dummyForm = document.createElement('form');
    const dummyFormInput1 = document.createElement('input');
    const dummyFormInput2 = document.createElement('input');

    dummyForm.action = path;
    dummyForm.method = 'POST';
    dummyFormInput1.name = '_method';   // rails uses _method post param for DELETE requests
    dummyFormInput1.type = 'hidden';
    dummyFormInput1.value = 'DELETE';
    dummyFormInput2.name = 'authenticity_token';
    dummyFormInput2.type = 'hidden';
    dummyFormInput2.value = this.context.utils.user.csrf;
    dummyForm.appendChild(dummyFormInput1);
    dummyForm.appendChild(dummyFormInput2);
    document.body.appendChild(dummyForm);
    dummyForm.submit();
  };

  mergeRivals = (rivalsToMerge = []) => {
    const rivals = (this.state.rivals || []).slice();

    if(_.isEmpty(rivalsToMerge)) {
      return rivals;
    }

    const rivalIds = rivals.map(r => r.id);

    for(let i = 0; i < rivalsToMerge.length; i++) {
      if(!rivalIds.includes(rivalsToMerge[i].id)) {
        rivals.push(rivalsToMerge[i]);
      }
    }

    return rivals;
  };

  getRivalsForGroup = async (groupId = 0) => {
    if(!this.state.showQuickSwitcher) {
      // only fetch rivals if quick switcher is visible
      return;
    }

    // refresh rivals if different group is toggled
    // TODO: potentially paginate this listing in the future (would need to load on scroll/group select)
    const rivalOptions = {navMode: true};
    const {rivalGroups} = this.props;

    if(isValidId(groupId, true)) {
      rivalOptions.rivalGroupId = groupId;

      const group = rivalGroups.find(g => g.id === groupId) || {rivals: []};
      const groupRivalIds = group.rivals.map(r => r.id);
      const rivalIds = (this.state.rivals || []).slice().map(r => r.id);

      // skip the rivalsList call if all of the group's rivals are already loaded in Navigation state
      if(groupRivalIds.every(rivalId => rivalIds.includes(rivalId))) {
        console.log('Navigation.getRivalsForGroup: rivals for groupId #%o already fully loaded', groupId);

        return;
      }
    }
    else {
      rivalOptions.recent = true;
    }

    const groupRivals = await rivalsList({rivalOptions, code: 'Navigation.getRivalsForGroup'});

    this.setState({
      rivals: this.mergeRivals(groupRivals)
    });
  };

  toggleActiveGroup = (groupId = null) => {
    const {activeGroupId} = this.state;

    this.setState({
      activeGroupId: groupId === activeGroupId ? null : groupId
    }, () => {
      if(this.state.activeGroupId !== null) {
        this.getRivalsForGroup(this.state.activeGroupId);
      }
    });
  };

  toggleIntercomButton = (enabled = true) => {
    // note: this element is injected by the intercom script and may change at any point
    const elIntercom = document.getElementById('intercom-container');

    if(elIntercom) {
      elIntercom.style.display = enabled ? 'block' : 'none';
    }
  };

  toggleBodyScrolling = (enabled = true) => {
    // prevent scrolling body when sidebar is open
    const elBody = document.body;

    if(elBody) {
      elBody.style.overflow = enabled ? '' : 'hidden';  // note: '' means remove inline style and fallback to css rules
    }
  };

  getCompanyQuickSwitcherResults = () => {
    const {companyFilter, filteredRivals, rivals} = this.state;

    // override rivals display with search results (if active)
    const rivalsToDisplay = ((companyFilter ? filteredRivals : rivals) || []).slice();

    const rivalsSort = (r1, r2) => {
      return r1.cardsCount < r2.cardsCount ? 1 : -1;
    };

    return rivalsToDisplay.sort(rivalsSort).slice(0, uiMaxResults.MAX_NAV_RESULTS);
  };

  // http://stackoverflow.com/questions/6018611/smallest-data-uri-image-possible-for-a-transparent-image
  getFallbackImage = () => 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

  getFinalUserImageSrc = imageUrl => {
    // resyncing an updated user profile image can be problematic, so we'll fallback to a transparent, non-broken image
    if(!imageUrl || imageUrl.includes('/missing/users')) {
      return this.getFallbackImage();
    }

    return imageUrl;
  };

  getVisibleRivals = () => (this.state.rivals || []).slice();

  handleDocumentKeyPress = event => {
    const {companyFilterInput} = this.refs;
    const isAlphaKeyRange = /^[a-z0-9]$/i.test(event.key);

    // capture and allow typing when quick switcher is open
    if(this.state.showQuickSwitcher && isAlphaKeyRange) {
      if(companyFilterInput && (document.activeElement !== ReactDOM.findDOMNode(companyFilterInput))) {
        companyFilterInput.focus();
      }
    }
  };

  handleDocumentKeyDown = event => {
    const {companyFilter, showSettingsSidebar, showQuickSwitcher} = this.state;
    const isEscKey = ['Escape', 'Esc'].includes(event.key);
    const isEnterOrDownKey = ['Enter', 'ArrowDown'].includes(event.key);
    const searchActive = Boolean(companyFilter.trim());

    // close sidebar & company switcher on ESC key
    if((showSettingsSidebar || showQuickSwitcher) && isEscKey) {
      if(searchActive) {
        // if in mid-search, just clear the search input and don't hide the sidebar (#2327)
        this.handleCompanySwitcherSearch(true);

        this.refs.companyFilterInput.focus();
      }
      else {
        this.resetState();

        if(showQuickSwitcher) {
          this.refs.quickSwitcherToggle.focus();
        }
      }
    }

    // company switcher, on enter key
    if(showQuickSwitcher && isEnterOrDownKey && companyFilter) {
      const firstCompanyDisplayedInQuickSwitcher = this.getCompanyQuickSwitcherResults()[0] || {};

      if(firstCompanyDisplayedInQuickSwitcher) {
        const firstCompany = firstCompanyDisplayedInQuickSwitcher || {};

        this.gotoPagePush(this.getBattlecardPath(firstCompany.profileId));
      }
      else {
        this.gotoPagePush('/');
      }
    }
  };

  handleNotificationBadgeClick = event => {
    if(event) {
      event.stopPropagation();
    }

    this.props.history.push('/notifications');
  };

  handleToggleSidebarClick = forceToggle => {
    const showSettingsSidebar = forceToggle === true || forceToggle === false ? forceToggle : !this.state.showSettingsSidebar;

    this.toggleIntercomButton(!showSettingsSidebar);
    this.toggleBodyScrolling(!showSettingsSidebar);

    this.setState({showSettingsSidebar});
  };

  handleToggleCompanySwitcherClick = (forceToggle, event) => {
    if(event?.type === 'keydown' && !['Enter', ' '].includes(event.key)) {
      return;
    }

    const showQuickSwitcher = forceToggle === true || forceToggle === false ? forceToggle : !this.state.showQuickSwitcher;

    if(event?.type === 'keydown' && showQuickSwitcher) {
      this.refs.companyFilterInput.focus();
    }

    this.toggleIntercomButton(!showQuickSwitcher);
    this.toggleBodyScrolling(!showQuickSwitcher);

    this.setState({showQuickSwitcher}, () => {
      this.getRivalsForGroup(this.state.activeGroupId);
    });
  };

  handleBattlecardDropdownOutsideClick = event => {
    // Check to make sure the click is not in the battlecard dropdown
    if(event.target && this.refs.battlecardDropdown && this.refs.battlecardDropdown.contains(event.target)) {
      return false;
    }

    document.body.removeEventListener('mousedown', this.handleBattlecardDropdownOutsideClick, false);
    document.body.removeEventListener('touchstart', this.handleBattlecardDropdownOutsideClick, false);

    if(this._isMounted) {
      this.setState({showBattlecardsDropdown: false});
    }
  };

  handleBattlecardDropdownToggle = event => {
    if(event) {
      event.preventDefault();
    }

    const {showBattlecardsDropdown} = this.state;

    document.body.removeEventListener('mousedown', this.handleBattlecardDropdownOutsideClick, false);
    document.body.removeEventListener('touchstart', this.handleBattlecardDropdownOutsideClick, false);

    if(!showBattlecardsDropdown) {
      document.body.addEventListener('mousedown', this.handleBattlecardDropdownOutsideClick, false);
      document.body.addEventListener('touchstart', this.handleBattlecardDropdownOutsideClick, false);
    }

    this.setState({showBattlecardsDropdown: !showBattlecardsDropdown});
  };

  handleCompanySwitcherSearch = debounce(args => this.handleCompanyFilter(args), 250);

  handleCompanyFilter = async (clearInput = false) => {
    const companyInput = this.refs.companyFilterInput;

    if(clearInput) {
      companyInput.value = '';
    }

    const companyFilter = companyInput ? (companyInput.value || '').trim() : '';
    const updatedState = {companyFilter};

    // store latest search to avoid incorrectly ordered async responses
    this._currentFilter = companyFilter;

    if(companyFilter) {
      const rivalOptions = {
        navMode: true,
        query: companyFilter
      };

      // TODO: use paginated rivalsGet here once rivalsList is merged into it (@gsar)
      const filteredRivals = await rivalsList({rivalOptions, code: 'Navigation.handleCompanySwitcherSearch'});

      Object.assign(updatedState, {
        filteredRivals,
        rivals: this.mergeRivals(filteredRivals)
      });
    }

    if(companyFilter === this._currentFilter) {
      this.setState(updatedState);
    }
  };

  renderMobileLinks = () => {
    const {appData, utils} = this.context;
    const {user, getCurrentLocation, isWinLossEnabled, isFeedHiddenInConsumerNavEnabled} = utils;
    const {v2Host} = appData;
    const pathName = getCurrentLocation();
    const uiNavButtons = [];
    const buttonClass = page => classNames('nav-sidebar-link', {'toolbar-button--active nav-sidebar-link--active': pathName.startsWith(page)});

    if(userCanCurate({user})) {
      uiNavButtons.push(
        <div key="1" className="u-pt-xs">
          <Link
            className={buttonClass('/alerts')}
            to="#"
            onClick={event => {
              event && event.preventDefault();

              redirectToV2({v2Host, v2Path: '/alerts'});
            }}>
            Alerts
          </Link>
        </div>
      );

      uiNavButtons.push(
        <div key="2" className="u-pt-xs">
          <Link
            className={buttonClass('/monitors')}
            to="#"
            onClick={event => {
              event && event.preventDefault();

              redirectToV2({v2Host, v2Path: '/monitors'});
            }}>
            Monitors
          </Link>
        </div>
      );
    }

    if(isWinLossEnabled()) {
      uiNavButtons.push(
        <div key="5" className="u-pt-xs">
          <Link
            className={buttonClass('/win-loss')}
            to="#"
            onClick={event => {
              event && event.preventDefault();

              redirectToV2({v2Host, v2Path: '/win-loss'});
            }}>
            <span>Win-Loss</span>
          </Link>
        </div>
      );
    }

    if(userCanCurate({user}) || !isFeedHiddenInConsumerNavEnabled()) {
      uiNavButtons.push(
        <div key="3" className="u-pt-xs">
          <Link
            to="/feed"
            className={buttonClass('/feed')}
            onClick={this.resetScrollPosition}>
            Feed
          </Link>
        </div>
      );
    }

    uiNavButtons.push(
      <div key="4" className="u-pt-xs">
        <Link
          to="/digest/overview"
          className={buttonClass('/digest')}
          onClick={this.resetScrollPosition}>
          Digest
        </Link>
      </div>
    );

    // NOTE: this is the mobile version markup; a desktop version is below
    const uiButtons = (
      <div>
        {uiNavButtons}
      </div>
    );

    return uiButtons;
  };

  renderRivalLink = (rival = null, isLastItem = false) => {
    const {rival: currentRival} = this.context.utils || {rival: {}};

    if(_.isEmpty(rival) || !isValidId(rival.id)) {
      return null;
    }

    const currentRivalClass = classNames('nav-sidebar-link', {
      'nav-sidebar-link--active': !_.isEmpty(currentRival) && (currentRival.id === rival.id)
    });
    const battlecardLink = this.getBattlecardPath(rival.profileId);

    return (
      <div key={rival.id ? `r_${rival.id}` : `rp_${rival.profileId}`} className={!isLastItem ? 'u-mb-xs' : ''}>
        <Link
          to={battlecardLink}
          title={rival.name}
          className={currentRivalClass}
          onClick={this.resetScrollPosition}>
          <span className="nav-sidebar-link_image"><CompanyLogo rival={rival} /></span>
          <span className="nav-sidebar-link_label">{rival.name}</span>
        </Link>
      </div>
    );
  };

  renderSidebarGroupings = ({items = [], heading = '', groupId = -1, profileMode = false}) => {
    const rivals = this.getVisibleRivals();
    const profilesList = [];
    const collapsed = groupId !== this.state.activeGroupId;
    let groupingBodyRegion;

    if(_.isEmpty(items)) {
      return;
    }

    if(!collapsed) {
      for(let i = 0; i < items.length; i++) {
        let rival = profileMode
          ? rivals.find(r => r.profileId === items[i].profileId)
          : rivals.find(r => r.id === items[i].id);

        // stub rival out to fill the group items listing if rivals are still loading (icon will pop in on load)
        if(!rival) {
          rival = {
            id: items[i].id,
            profileId: items[i].profileId,
            name: items[i].name
          };
        }

        if(rival) {
          profilesList.push(
            this.renderRivalLink(rival, (i + 1 === items.length))
          );
        }
      }

      groupingBodyRegion = (
        <div className="nav-sidebar-grouping_body">
          {profilesList}
        </div>
      );
    }

    const groupingClasses = classNames('nav-sidebar-grouping', {
      'nav-sidebar-grouping--collapsed u-mb-m': collapsed,
      'u-mb-l': !collapsed
    });

    return (
      <div title={heading} key={`grouping_${groupId}`} className={groupingClasses}>
        <div className="nav-sidebar-header u-mb-s" onClick={() => this.toggleActiveGroup(groupId)}>
          <div className="nav-sidebar-header_text" title={heading}>{heading}</div>
          <div className="nav-sidebar-header_icon">
            <Icon icon={collapsed ? 'arrow-down' : 'arrow-up'} width="18" height="18" />
          </div>
        </div>
        {groupingBodyRegion}
      </div>
    );
  };

  renderRivalGroups = (groups = []) => {
    if(_.isEmpty(groups)) {
      return;
    }

    return groups.map(g => this.renderSidebarGroupings({
      items: g.rivals,
      heading: g.name,
      groupId: g.id
    }));
  };

  renderCompanyQuickSwitcher = () => {
    const {defaultGroupId} = this.props;
    const {companyFilter, showQuickSwitcher} = this.state;
    const {company, user, getCurrentLocation} = this.context.utils;
    const isImpersonating = userIsKluebot({userId: user.id, company});
    const rivalGroups = this.props.rivalGroups || [];
    const pathName = getCurrentLocation();
    const rivalsForDisplay = this.getCompanyQuickSwitcherResults();
    const searchActive = Boolean(companyFilter.trim());
    const isDashboard = ['', '/dashboard'].includes(pathName);
    const dashboardLinkClasses = classNames('nav-sidebar-link', {
      'nav-sidebar-link--active nav-sidebar-link--wrap': isDashboard
    });
    const isResearchEnabled = userCanCurate({user}) && (
      company?.companyData?.researchEnabled ||
      (company?.companyData?.researchEnabledForStaff && isImpersonating)
    );
    const isComposerEnabled = userCanCurate({user}) && (
      company?.companyData?.isComposerEnabled ||
      (company?.companyData?.isComposerEnabledForStaff && isImpersonating)
    );
    let recentlyViewedProfiles = [];
    let uiRivalResultsList = [];
    let uiRivalResultsHeader;
    let uiSeeAllButton;
    let uiRecentRivals;
    let uiRivalGroups;
    let uiSearchIcon = (
      <div className="nav-sidebar-search_icon">
        <Icon icon="search" width="18" height="18" />
      </div>
    );
    let companiesLabel = 'Your Companies';

    // pull in currently active group for user/company (if any)
    if(defaultGroupId && rivalGroups.length) {
      const group = rivalGroups.find(g => g.id === defaultGroupId);

      if(group) {
        companiesLabel = group.name;
      }
    }

    if(!_.isEmpty(user)) {
      recentlyViewedProfiles = dig(user, 'userData.recentlyViewedProfiles') || [];

      if(!_.isEmpty(recentlyViewedProfiles) && !searchActive) {
        uiRecentRivals = this.renderSidebarGroupings({
          items: recentlyViewedProfiles.map(id => ({profileId: id})),
          heading: 'Recently Viewed',
          profileMode: true
        });
      }
    }

    if(!searchActive && rivalGroups.length) {
      uiRivalGroups = this.renderRivalGroups(rivalGroups);
    }

    if(searchActive || ((recentlyViewedProfiles.length <= Navigation.MIN_RECENT_PROFILES) && !rivalGroups.length)) {
      // if less than 3 recently viewed profiles, show "top" companies list too
      if(recentlyViewedProfiles.length > 0) {
        // only show secondary header in combination with > 0 recent profiles
        uiRivalResultsHeader = (
          <div className="nav-sidebar-header u-mb-s">Matching Companies</div>
        );
      }

      for(let i = 0; i < rivalsForDisplay.length; i++) {
        uiRivalResultsList.push(
          this.renderRivalLink(rivalsForDisplay[i], (i + 1 === rivalsForDisplay.length))
        );
      }
    }

    if(searchActive) {
      uiSearchIcon = (
        <div className="nav-sidebar-search_icon nav-sidebar-search_icon--clear" onClick={() => this.handleCompanySwitcherSearch(searchActive)}>
          <Icon icon="close" width="18" height="18" />
        </div>
      );

      // no companies to display
      if(!uiRivalResultsList.length) {
        uiRivalResultsList = (
          <div className="u-mb-l">
            <div className="nav-sidebar-no-results">Sorry, no results were found.</div>
          </div>
        );

        // bigger, bolder "see all" button when no results found
        uiSeeAllButton = (
          <div>{/* <- extra div to re-render whole element (not just class names) to prevent css animation transition */}
            <Link
              to="/"
              className="button button--size-normal button--primary u-w100 text-uppercase klue-ui"
              onClick={this.resetScrollPosition}>
              <strong>See All</strong>
            </Link>
          </div>
        );
      }
    }

    return (
      <div
        className={'quick-switcher nav-sidebar nav-sidebar--left' + (showQuickSwitcher ? ' nav-sidebar--active' : '')}
        onClick={() => this.handleToggleCompanySwitcherClick(false)}>
        <div className="nav-sidebar_close">
          <Icon icon="close" width="24" height="24" />
        </div>
        <div className="nav-sidebar_panel" onClick={e => e.stopPropagation()}>
          <div className="nav-sidebar_panel_contain">
            <div className="nav-sidebar-header u-mb-s">Dashboard</div>
            <Link
              to="/dashboard"
              className={dashboardLinkClasses}
              onClick={this.resetScrollPosition}>
              <span className="nav-sidebar-link_icon nav-sidebar-link_icon--left">
                <Icon icon="dashboard" width="24" height="24" style={{margin: '-2px'}} />
              </span>
              {companiesLabel}
            </Link>
            {isResearchEnabled ? (
              <Link
                to="/research"
                className="nav-sidebar-link"
                onClick={this.resetScrollPosition}>
                <span className="nav-sidebar-link_icon nav-sidebar-link_icon--left">
                  <Icon icon="beaker" width="24" height="24" style={{margin: '-2px'}} />
                </span>
                Research
              </Link>
            ) : null}
          </div>
          <div className="nav-sidebar_panel_contain">
            <div className="nav-sidebar-header u-mb-s">Quick Finder</div>
            <div className="nav-sidebar-search u-mb-l">
              {uiSearchIcon}
              <input
                ref="companyFilterInput"
                type="search"
                className="nav-sidebar-search_input"
                placeholder="Company name&hellip;"
                onKeyUp={e => this.handleCompanySwitcherSearch(false, e)} />
            </div>
            {uiRecentRivals}
            {uiRivalResultsHeader}
            {uiRivalResultsList}
            {uiRivalGroups}
            {uiSeeAllButton}
          </div>
        </div>
      </div>
    );
  };

  handleReportsNav = e => {
    e?.preventDefault();

    const {appData} = this.context;
    const {v2Host} = appData;

    redirectToV2({v2Host, v2Path: '/reports'});
  };

  renderSettingsSidebar = () => {
    const {notificationCounts = {}} = this.props;
    const {showSettingsSidebar} = this.state;
    const {utils, appData} = this.context;
    const {user, company, userCanImpersonate, getCurrentLocation} = utils;
    const {helpDocsHost, v2Host} = appData;
    const pathName = getCurrentLocation();
    const isImpersonating = userIsKluebot({userId: user.id, company});
    const uiNotificationCount = (notificationCounts && notificationCounts.totalNotifications) ? notificationCounts.totalNotifications : '';
    const userProfileLink = `/users/${user.id}`;
    let uiStaffSection;
    let uiCuratorSection;

    if(userCanImpersonate() || isImpersonating) {
      let uiStaffImpersonateToggle;

      if(isImpersonating) {
        // note: appData.staffUser can be undefined in same cases (weird logged out session?)
        const username = (this.context.appData.staffUser || {}).at_name;

        // if no username, typically it means that the session has expired
        const revertClickAction = e => this.gotoPathWithDeleteMethod(username ? '/impersonate/revert' : '/account/signout', e);

        uiStaffImpersonateToggle = (
          <div>
            <a
              href="#"
              className="button button--primary klue-ui intel"
              onClick={revertClickAction}>Revert {username ? `to @${username}` : 'by re-logging in'}
            </a>
          </div>
        );
      }
      else {
        uiStaffImpersonateToggle = (
          <div>
            <a
              href="/impersonate"
              className="nav-sidebar-link"
              onClick={e => this.gotoPageExternal('/impersonate', true, e)}>
              Switch Instance
            </a>
          </div>
        );
      }

      uiStaffSection = (
        <div className="u-pt-xl">
          <div className="nav-sidebar-header u-mb-s">Klue Staff</div>
          {uiStaffImpersonateToggle}
        </div>
      );
    }

    if(userCanCurate({user})) {
      const {usageReports} = appData;
      const isAdmin = userIsAdmin({user});
      const canCuratorSeeReport = company?.companyData?.isConsumerReportEnabledForCurators ||
        company?.companyData?.isCuratorReportEnabledForCurators ||
        company?.companyData?.isCRAReportEnabledForCurators;
      const reportsNavEnabled = (isAdmin || canCuratorSeeReport) && Object.values(usageReports || {}).length;
      const uiCuratorSectionHeading = isAdmin ? 'Admin' : 'Curators';
      let uiCuratorDigestSection;

      uiCuratorSection = (
        <div className="u-mb-xl">
          <div><div className="nav-sidebar-header u-mb-s">{uiCuratorSectionHeading}</div></div>
          {uiCuratorDigestSection}
          <div>
            <Link
              to="/users"
              className={'nav-sidebar-link' + (pathName === '/users' ? ' nav-sidebar-link--active' : '')}
              onClick={this.resetScrollPosition}>
              Manage Users
            </Link>
          </div>
          <div>
            <Link
              to="/settings"
              className={'nav-sidebar-link' + (pathName === '/settings' ? ' nav-sidebar-link--active' : '')}
              onClick={this.resetScrollPosition}>
              Company Settings
            </Link>
          </div>
          {reportsNavEnabled
            ? (
              <div>
                <a
                  className={'nav-sidebar-link' + (pathName.startsWith('/reports') ? ' nav-sidebar-link--active' : '')}
                  data-tracking-id="nav-sidebar-link-reports"
                  tabIndex={0}
                  onClick={this.handleReportsNav}>
                  Reports
                </a>
              </div>
            )
            : null}
        </div>
      );
    }

    return (
      <div
        className={'user-menu nav-sidebar nav-sidebar--right nav-sidebar--logo' + (showSettingsSidebar ? ' nav-sidebar--active' : '')}
        onClick={() => this.handleToggleSidebarClick(false)}>
        <div className="nav-sidebar_close">
          <Icon icon="close" width="24" height="24" />
        </div>
        <div className="nav-sidebar_panel" onClick={e => e.stopPropagation()/* Prevent click from triggering parent click*/}>
          <div className="nav-sidebar_panel_contain">

            <div className="nav-sidebar-account">
              <Link
                to={userProfileLink}
                className="nav-sidebar-account_avatar"
                onClick={this.resetScrollPosition}>
                <img src={this.getFinalUserImageSrc(user.imageMed)} loading="lazy" />
              </Link>
              <div className="nav-sidebar-account_details">
                <Link
                  to={userProfileLink}
                  className="nav-sidebar-account_details_handle"
                  onClick={this.resetScrollPosition}>
                  {user.username ? `@${user.username}` : 'You'}
                </Link>
                {/* TODO: add direct user profile edit route & link up here */}
                <Link
                  to={userProfileLink}
                  className="nav-sidebar-account_details_link nav-sidebar-account_details_link--parent-over"
                  onClick={this.resetScrollPosition}>
                  Edit Profile
                </Link>
                <div className="nav-sidebar-account_details_link"
                  onClick={() => this.gotoPathWithDeleteMethod('/account/signout')}>Sign Out</div>
              </div>
            </div>

          </div>
          {utils.company?.companyData?.isNewCuratorNavEnabled && userCanCurate({user}) ? undefined :
          <div className="nav-sidebar_panel_contain nav-sidebar_panel_contain--mobile-only">
            {this.renderMobileLinks()}
          </div>}
          <div className="nav-sidebar_panel_contain">
            <div className="u-mb-xl">
              <Link
                to="/notifications"
                className={'notifications nav-sidebar-link' + (pathName === '/notifications' ? ' nav-sidebar-link--active' : '')}
                onClick={this.resetScrollPosition}>
                <span className="nav-sidebar-link_label">Notifications</span>
                <span className="nav-sidebar-link_indicator">{uiNotificationCount}</span>
              </Link>
            </div>
            {uiCuratorSection}
            <div>
              <div className="nav-sidebar-header u-mb-s">Tips</div>
              <div>
                <a
                  href={`//${v2Host}/integrations`}
                  className={'nav-sidebar-link' + (pathName === '/integrations' ? ' nav-sidebar-link--active' : '')}>
                  Apps &amp; Integrations
                </a>
              </div>
              <div>
                <a
                  href={`//${helpDocsHost}`}
                  className="nav-sidebar-link"
                  onClick={e => this.gotoPageExternal(`//${helpDocsHost}`, false, e)}>
                  FAQs &amp; Guides
                  <div className="nav-sidebar-link_external"><Icon icon="external" width="18" height="18" /></div>
                </a>
              </div>
              <div>
                <a
                  href={`//${helpDocsHost}/what-s-new`}
                  className="nav-sidebar-link"
                  onClick={e => this.gotoPageExternal(`//${helpDocsHost}/what-s-new`, false, e)}>
                  What&apos;s New
                  <div className="nav-sidebar-link_external"><Icon icon="external" width="18" height="18" /></div>
                </a>
              </div>
            </div>
            {uiStaffSection}
            {/* logo must be in the last panel_contain due to positioning */}
            <a
              href="https://www.klue.com"
              className="nav-sidebar_panel_contain_logo"
              onClick={e => this.gotoPageExternal('https://www.klue.com', false, e)}>
              <KlueLogo />
            </a>
          </div>
        </div>
      </div>
    );
  };

  handleBattlecardDropdownClick = (battlecardId, event, previewing) => {
    if(event) {
      event.preventDefault();
    }

    const {rival} = this.context.utils;

    if(!rival) {
      return;
    }

    if(previewing) {
      const {location} = this.props;

      return this.gotoPagePush(`/profile/${rival.profile.id}/battlecard${battlecardId > 0 ? `/${battlecardId}` : ''}${location.search}`);
    }

    this.gotoPagePush(`/profile/${rival.profile.id}/battlecard${battlecardId > 0 ? `/${battlecardId}` : ''}`);
  };

  handleProfileButtonClick = (previewing, event) => {
    if(event) {
      event.preventDefault();
    }

    const {location} = this.props;
    const {appData: {v2Host}, utils: {isNewCardEditorEnabled, rival, getCurrentLocation, user}} = this.context;

    if(!rival) {
      return;
    }

    // Dont goto profile page if we're editing in that profile
    const pathName = getCurrentLocation();
    const profileBase = `/profile/${rival.profile.id}`;
    const profilePath = `${profileBase}/${userCanCurate({user}) ? 'battlecard/edit' : 'view'}${location.search}${location.hash}`;

    if(isNewCardEditorEnabled()) {
      return redirectToV2({v2Host, v2Path: profilePath});
    }

    const inProfileEditMode = pathName.startsWith(profileBase) && pathName.includes('/edit');

    if(inProfileEditMode) {
      return;
    }

    this.gotoPagePush(profilePath);
  };

  renderCurrentCompanyNavigationItems = () => {
    const {currentPreviewBattlecards} = this.state;
    const {showBattlecardsDropdown} = this.state;
    const {rival, battlecard, getCurrentLocation} = this.context.utils;
    const pathName = getCurrentLocation();
    const profile = rival ? rival.profile : null;
    const currentRivalBattlecards = profile
      ? !currentPreviewBattlecards
        ? (profile.battlecards || [])
        : currentPreviewBattlecards
      : [];
    const isBattlecardSection = pathName.includes('/battlecard') && !pathName.includes('/edit');
    const isProfileSection = pathName.startsWith('/profile') && !isBattlecardSection;
    const currentBattlecard = battlecard || null;
    const currentBattlecardIndex = currentBattlecard ? _.findIndex(currentRivalBattlecards, {id: currentBattlecard.id}) : -1;
    const uiButtonCompanyLabel = (rival ? rival.name : null) || '(untitled)';
    const isCurrentProfileDraft = profile ? profile.isDraft : false;
    const battlecardDropdownClasses = classNames('head-dropdown-group', {
      'head-dropdown-group--active': showBattlecardsDropdown
    });
    const uiButtonDropdownBattlecards = [];
    let uiButtonDropdown;
    let uiButtonDropdownLabel;
    let showDropdownLabelInDraftMode;

    // Only output company nav if we have a company (aka. rival)
    if(!rival) {
      return null;
    }

    if(isBattlecardSection && currentBattlecardIndex > -1) {
      const {title: tempTitle, isDraft} = currentRivalBattlecards[currentBattlecardIndex];

      showDropdownLabelInDraftMode = isDraft;
      uiButtonDropdownLabel = tempTitle !== null && tempTitle.length > 0 ? tempTitle : '(Untitled battlecard)';
    }
    else {
      showDropdownLabelInDraftMode = false;
      uiButtonDropdownLabel = 'Battlecards';
    }

    currentRivalBattlecards.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));

    for(let i = 0; i < currentRivalBattlecards.length; i++) {
      const isCurrentBattlecard = isBattlecardSection && currentBattlecardIndex === i;
      const tempTitle = currentRivalBattlecards[i].title;
      const {isDraft} = currentRivalBattlecards[i];
      const currentTitle = tempTitle !== null && tempTitle.length > 0 ? tempTitle : '(Untitled battlecard)';

      uiButtonDropdownBattlecards.push(
        <div key={i} className="head-dropdown-grid_row">
          <a
            href="#"
            className={
              'head-main-item head-main-item--dropdown head-main-item--no-sep head-main-item--truncate' +
              (isCurrentBattlecard ? ' head-main-item--active' : '')
            }
            onClick={e => this.handleBattlecardDropdownClick(currentRivalBattlecards[i].id, e, Boolean(currentPreviewBattlecards))}>
            {isDraft
              ? <div className="head-main-item_icon head-main-item_icon--left head-main-item--draft">
                <Icon icon="visibility-off" width="18" height="18" />
              </div>
              : null}
            <span className="head-main-item_label">
              <span>{currentTitle}</span>
            </span>
          </a>
        </div>
      );
    }

    if(currentRivalBattlecards.length) {
      uiButtonDropdown = (
        <div key="b-0" id="onboardBCStep3Target1" ref="battlecardDropdown" className={battlecardDropdownClasses}>
          <div className={
              'head-main-item head-main-item--end-cap' +
              (showBattlecardsDropdown ? ' head-main-item--focused head-main-item--active' : '') +
              (isBattlecardSection ? ' head-main-item--current' : '') + battlecardDropdownClasses + ' head-dropdown-group_trigger'
            }>
            <a
              href="#"
              data-cy="battlecardsToolbarLink"
              className={
                (showDropdownLabelInDraftMode ? ' head-main-item--draft' : '')
              }
              onClick={this.handleBattlecardDropdownToggle}
              data-tracking-id="battlecard-dropdown-link">
              {showDropdownLabelInDraftMode
                ? <div className="head-main-item_icon head-main-item_icon--left head-main-item--draft">
                  <Icon icon="visibility-off" width="18" height="18" />
                </div>
                : null
                }
              <span>{uiButtonDropdownLabel}</span>
              <span className="head-main-item_icon head-main-item_icon--right">
                <Icon icon={(showBattlecardsDropdown ? 'close' : 'arrow-down')} width="16" height="16" />
              </span>
            </a>
          </div>
          <div className="head-dropdown-group_dropdown">
            <div className="head-dropdown-grid">
              {uiButtonDropdownBattlecards}
            </div>
          </div>
        </div>
      );
    }
    else if(currentPreviewBattlecards && currentPreviewBattlecards.length < 1) {
      uiButtonDropdown = null;
    }
    else {
      uiButtonDropdown = (
        <div
          key="b-0"
          id="onboardBCStep3Target1"
          className={'head-main-item head-main-item--end-cap ' + (isBattlecardSection ? ' head-main-item--current' : '')}>
          <a
            href="#"
            onClick={e => this.handleBattlecardDropdownClick(0, e)}
            data-tracking-id="battlecard-dropdown-link"
            data-cy="battlecardsToolbarLink">
            <span>
              Battlecards
            </span>
          </a>
        </div>
      );
    }

    const uiButtonProfile = (
      <div
        key="b-1"
        data-testid="nav-rival-link"
        className={
          'head-main-item head-main-item--truncate head-main-item--active' +
          (isProfileSection ? ' head-main-item--current' : '') +
          (isCurrentProfileDraft ? ' head-main-item--draft' : '')
        }
        onClick={e => this.handleProfileButtonClick(Boolean(currentPreviewBattlecards), e)}>
        {isCurrentProfileDraft ?
          (<div className="head-main-item_icon head-main-item_icon--left"><Icon icon="visibility-off" width="18" height="18" /></div>) : null
        }
        <a className="head-main-item_label" href="#">
          <span>{uiButtonCompanyLabel}</span>
        </a>
      </div>
    );

    return [uiButtonProfile, uiButtonDropdown];
  };

  renderMainNavigation = () => {
    const {appData, utils} = this.context;
    const {v2Host} = appData;
    const {notificationCounts = {}} = this.props;
    const {user, company, getCurrentLocation, isWinLossEnabled, isNewCuratorNavEnabled, isFeedHiddenInConsumerNavEnabled} = utils;
    const pathName = getCurrentLocation();
    const isImpersonating = userIsKluebot({userId: user.id, company});
    const uiNotificationCount = (notificationCounts && notificationCounts.totalNotifications) ? notificationCounts.totalNotifications : '';
    const companyName = company ? company.name : '';
    const inProfile = pathName.startsWith('/profile');
    const buttonClass = page => classNames('head-main-item', {'head-main-item--current': pathName.startsWith(page)});
    let navButtons = [];
    const isResearchEnabled = userCanCurate({user}) && (
      company?.companyData?.researchEnabled ||
      (company?.companyData?.researchEnabledForStaff && isImpersonating)
    );
    const isNewCuratorNavEnabledFeatureFlag = isNewCuratorNavEnabled() && userCanCurate({user});
    const isComposerEnabled = userCanCurate({user}) && (
      company?.companyData?.isComposerEnabled ||
      (company?.companyData?.isComposerEnabledForStaff && isImpersonating)
    );
    let uiInstanceLabel;

    const discoverItems = [
      {id: 'alerts', label: 'Alerts', path: '/alerts', icon: 'alertsIcon', isV2: true},
      {id: 'monitors', label: 'Monitors', path: '/monitors', icon: 'binocularsIcon', isV2: true},
      {id: 'salesCalls', label: 'Sales Calls', path: '/call-insights', icon: 'phoneIcon', isV2: true, hasNewBadge: true}
    ];

    discoverItems.push({id: 'win-loss', label: 'Win-Loss', path: '/win-loss', icon: 'winLossIcon', isV2: true, hasUpgradeBadge: !isWinLossEnabled()});

    if(userIsAdmin({user}) || userCanCurate({user}) || user.isImpersonating) {
      discoverItems.push({id: 'insights', label: 'Insights', path: '/insights', icon: 'insightsIcon', isV2: true});
    }

    const shareItems = [
      {id: 'digest', label: 'Digest', path: '/digest/overview', icon: 'digestIcon', isV2: false},
      {id: 'feed', label: 'Feed', path: '/feed', icon: 'feedIcon', isV2: false},
      {id: 'boards', label: 'Boards', path: '/dashboard', icon: 'boardsIcon', isV2: false},
      {id: 'integrations', label: 'Integrations', path: '/integrations', icon: 'integrationIcon', isV2: true}
    ];

    if(isComposerEnabled) {
      shareItems.push({id: 'composer', label: 'Composer', path: '/composer', icon: 'magic', isV2: true});
    }

    const groups = [
      {title: 'discover', items: discoverItems},
      {title: 'share', items: shareItems}
    ];

    if((userIsAdmin({user}) || userCanCurate({user})) && Object.values(appData.usageReports || {}).length) {
      let measureItems = [];
      const consumerUsage = {id: 'consumer-usage', label: 'Consumer Usage', path: 'reports/consumer-usage/overview', icon: 'consumerUsageIcon', isV2: true};
      const curatorActivity = {id: 'curator-activity', label: 'Curator Activity', path: '/reports/curator', icon: 'curatorActivityIcon', isV2: true};
      const cra = {
        id: 'competitive-analytics', label: 'Competitive Analytics', path: '/reports/competitive-analytics/overview', icon: 'analyticsIcon', isV2: true
      };

      if(userIsAdmin({user})) {
        measureItems = [consumerUsage, curatorActivity, cra];
      }
      else if(userCanCurate({user})) {
        const isCraEnabled = company?.companyData?.isCRAReportEnabledForCurators;
        const isConsumerEnabled = company?.companyData?.isConsumerReportEnabledForCurators;
        const isCuratorEnabled = company?.companyData?.isCuratorReportEnabledForCurators;

        if(isCraEnabled) {measureItems.push(cra);}
        if(isConsumerEnabled) {measureItems.push(consumerUsage);}
        if(isCuratorEnabled) {measureItems.push(curatorActivity);}
      }
      if(measureItems.length > 0) {groups.push({title: 'measure', items: measureItems});}
    }

    // NOTE: this is the desktop button version; the mobile version is above
    if(userCanCurate({user})) {
      navButtons.push(
        <div key="cb-0" className={buttonClass('/alerts')} data-tracking-id="main-header-item-alerts">
          <Link
            to="#"
            onClick={event => {
              event && event.preventDefault();

              redirectToV2({v2Host, v2Path: '/alerts'});
            }}>
            <span>Alerts</span>
          </Link>
        </div>,
        <div key="cb-1" className={buttonClass('/monitors')}>
          <Link
            to="#"
            onClick={event => {
              event && event.preventDefault();

              redirectToV2({v2Host, v2Path: '/monitors'});
            }}>
            <span>Monitors</span>
          </Link>
        </div>
      );
    }

    if(isWinLossEnabled()) {
      navButtons.push(
        <div key="cb-4" className={buttonClass('/win-loss')}>
          <Link
            to="#"
            data-tracking-id="app-nav-bar_win-loss"
            onClick={event => {
              event && event.preventDefault();

              redirectToV2({v2Host, v2Path: '/win-loss'});
            }}>
            <span>Win-Loss</span>
          </Link>
        </div>
      );
    }

    if(userCanCurate({user}) || !isFeedHiddenInConsumerNavEnabled()) {
      navButtons.push(
        <div key="cb-2" className={buttonClass('/feed')}>
          <Link
            to="/feed"
            onClick={this.resetScrollPosition}>
            <span>Feed</span>
          </Link>
        </div>
      );
    }

    navButtons.push(
      <div key="cb-3" className={buttonClass('/digest')}>
        <Link
          to="/digest/overview"
          onClick={this.resetScrollPosition}>
          <span>Digest</span>
        </Link>
      </div>
    );

    if(isNewCuratorNavEnabledFeatureFlag) {
      navButtons = [];
      groups.forEach(group => {
        navButtons.push(
          <NavigationDropdown key={group.title} title={group.title} items={group.items} />
        );
      });
    }

    // show current instance name when on any non-profile pages
    if(!inProfile && companyName) {
      uiInstanceLabel = (
        <>
          <div className="head-main-item head-main-item--end-cap head-main-item--truncate">
            <Link
              to="/"
              onClick={this.resetScrollPosition}>
              <span>{isImpersonating ? `HOME (${companyName})` : 'HOME'}</span>
            </Link>
          </div>
          {isResearchEnabled ? (
            <div className="head-main-item head-main-item--end-cap head-main-item--truncate">
              <Link
                to="/research"
                onClick={event => {
                  event && event.preventDefault();

                  redirectToV2({v2Host, v2Path: '/research'});
                }}>
                <span>RESEARCH</span>
              </Link>
            </div>
          ) : null}
        </>
      );
    }

    return (
      <div className={'header' + (isImpersonating ? ' header--impersonating' : '')}>
        <div className="header_primary">
          {/* NOTE: this is the desktop button version; the mobile version is above */}
          <div
            tabIndex="0"
            id="onboardBCStep1Target2"
            ref="quickSwitcherToggle"
            className={'head-main-item head-main-item--no-sep'}
            onClick={() => this.handleToggleCompanySwitcherClick(true)}
            onKeyDown={event => this.handleToggleCompanySwitcherClick(true, event)}>
            <div className="head-icon-burger" />
          </div>
          {uiInstanceLabel}
          {this.renderCurrentCompanyNavigationItems()}
          <SearchSuggester />
        </div>

        <div className={classNames('header_secondary', {'header_secondary--curator': isNewCuratorNavEnabledFeatureFlag})}>
          {/* NOTE: this is the desktop items */}
          {navButtons}
        </div>
        {
          isNewCuratorNavEnabledFeatureFlag && (<div className="header_secondary--mobile">
            {/* NOTE: this is the mobile items */}
            <NavigationDropdownCombined groups={groups} />
          </div>)
        }

        <div className="header_action">
          <div className={'head-settings-trigger' + (isImpersonating ? ' head-settings-trigger--revert' : '')}>
            <div className="head-notification" onClick={this.handleNotificationBadgeClick}>
              <div className="head-settings-trigger_avatar">
                <img src={this.getFinalUserImageSrc(user.imageMed)} loading="lazy" />
              </div>
              <div className="head-settings-trigger_indicator">{uiNotificationCount}</div>
            </div>
            <div
              className="head-settings-trigger_icon"
              data-tracking-id="head-settings-trigger_icon"
              onClick={() => this.handleToggleSidebarClick(true)}>
              <Icon icon="more-vert" height="18" width="18" />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderMainNavigation()}
        {this.renderSettingsSidebar()}
        {this.renderCompanyQuickSwitcher()}
      </div>
    );
  }

}

export default withRouter(Navigation);
