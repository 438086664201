import linkifyHtml from 'linkifyjs/html';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import FroalaEditor from 'froala-editor';
import {normalizeAttachment, getFileType} from './attachment_utils';
import {parseHostname} from './url_utils';
import {
  EDITOR_PLACEHOLDER_CLASS,
  EDITOR_IMG_PLACEHOLDER_CLASS,
  FORMULA_REGEX,
  COMPLETE_FORMULA_REGEX,
  formulaToKeyMappings,
  INTERNAL_LINK_CLASSNAME,
  EXTERNAL_LINK_CLASSNAME,
  IMAGE_LINK_CLASSNAME,
  TABLE_WRAP_CLASSNAME,
  timeseriesFormulas,
  editorTextColors,
  editorFontSizes
} from './constants/editor';
import {uploadFileToS3} from '../modules/s3_utils';
import imageErrorPlaceholder from '../../images/editor-img-placeholder.png';
import {htmlToText} from 'html-to-text';
import {analyticsTrack, SNOWPLOW_SCHEMAS} from '../modules/analytics_utils';

export const isValidFormula = (formula = '') => COMPLETE_FORMULA_REGEX.test(formula);

export const getDomainFromFormula = (formula = '', rival = null) => {
  let domain = '';

  if(!formula || _.isEmpty(rival) || !rival.url) {
    return '';
  }

  const rivalDomain = parseHostname(rival.url);
  const companyRegex = /COMPANY\("(.+)"\)/gim;
  const matches = [];
  let item;

  // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#Description
  while(item = companyRegex.exec(formula)) {
    if(item && (item.length > 1)) {
      matches.push(item[1]);
    }
  }

  if(matches.length) {
    const filteredMatches = matches.filter(d => d.toLowerCase() !== rivalDomain.toLowerCase());

    domain = (filteredMatches.shift() || '').trim();
  }

  // fall back to current rival domain in case of an incomplete/unsaved formula (i.e. open in dynamic block editor)
  return domain || rivalDomain;
};

export const getFormulaTerms = formula => ((formula || '').replace(/\s/gm, '').split('(') || []);

export const getFormulaKey = (formula = '', isNew = false) => {
  if(isNew) {
    // "default" formula (name only, no arguments) to pre-populate wizard for new dynamic blocks
    return formulaToKeyMappings[formula];
  }

  // fully-formed formula including arguments (editing existing dynamic block)
  const formulaTerms = getFormulaTerms(formula);

  if(formulaTerms.includes('SFDC_LATEST_OPPORTUNITIES')) {
    return formulaToKeyMappings.SFDC_LATEST_OPPORTUNITIES;
  }

  if(formulaTerms.includes('SFDC_WINS')) {
    return formulaToKeyMappings.SFDC_WINS;
  }

  const idx = Math.abs(formulaTerms.findIndex(term => (/COMPANY|WEBSITE/i).test(term)));

  const formulaName = `${(/COMPARE/gi).test(formula) ? 'COMPARE_' : ''}${formulaTerms[(idx > 0) ? (idx - 1) : 0] || ''}`;

  return formulaToKeyMappings[formulaName] || '';
};

/**
 * Wrap a table to make it scrollable
 *
 * @param {Element} table DOM object
 */
export const makeTableScrollable = table => {
  const wrapper = document.createElement('div');

  wrapper.className = TABLE_WRAP_CLASSNAME;
  table.parentNode.insertBefore(wrapper, table);
  wrapper.appendChild(table);

  table.classList.add('table-borders--all');
};

export const normalizedCompanyFormulaParam = (param, currentRivalId) => {
  if(param === currentRivalId || `${param}` === `${currentRivalId}`) {
    return 'currentRival';
  }

  if(param === 0 || `${param}` === '0') {
    return 'currentCompany';
  }

  return param;
};

// formula could be EMPLOYEE_COUNT or REVENUES. companyA/B value should be rivalId, domain, or 'currentRival'/'currentCompany'
export const timeseriesFormula = (formula, companyAValue, companyBValue) => {
  return `COMPARE_TIMESERIES(
    NORMALIZE_TIMESERIES(${formula}(${companyAValue})),
    NORMALIZE_TIMESERIES(${formula}(${companyBValue})))`;
};

const populatedDefaultFormula = formula => {
  if(timeseriesFormulas.includes(formula)) {
    return timeseriesFormula(formula.substring('COMPARE_'.length), 'currentRival', 'currentCompany');
  }

  return `${formula}(COMPANY("currentRival"))`;
};

export const normalizeHtmlContent = (textHtml = '', formulas = []) => {
  const matchedFormulas = [];

  if(!textHtml) {
    return {textHtml, matchedFormulas};
  }

  const fragment = new DOMParser().parseFromString(textHtml, 'text/html');
  const dynamicEls = fragment.querySelectorAll(`div.${EDITOR_PLACEHOLDER_CLASS}`);

  // parse and replace any dynamic card template blocks
  // also see: https://github.com/kluein/klue/issues/4904#issuecomment-529676860
  dynamicEls.forEach((node, index) => {
    let formula = formulas[index];

    if(formula) {
      if(!isValidFormula(formula)) {
        // formula is unsaved/incomplete, populate with default values
        formula = populatedDefaultFormula(formula);
      }

      formula = formula.replace(/\s/gm, '');

      const formulaNode = document.createTextNode(`{{${formula}}}`);

      // create new map to contain current set of formulas (in case any have been removed via the editor)
      matchedFormulas.push(formula);

      node.replaceWith(formulaNode);
    }
    else {
      // clean out any open/unsaved formula editor blocks
      node.remove();
    }
  });

  // replace failed uploaded images by a default placeholder
  fragment.querySelectorAll('img.upload-failed').forEach(img => {
    img.classList.add(EDITOR_IMG_PLACEHOLDER_CLASS);
    img.src = '/editor-img-placeholder.png';
  });

  // Wrap tables to enable horizontal scrolling
  fragment.querySelectorAll('table').forEach(table => {
    // ignore already wrapped components
    if(table.parentElement && table.parentElement.classList.contains(TABLE_WRAP_CLASSNAME)) {
      return;
    }

    makeTableScrollable(table);
  });

  // detect and add extra classes to external and internal links
  fragment.querySelectorAll('a').forEach(link => {
    link.classList.remove(EXTERNAL_LINK_CLASSNAME, INTERNAL_LINK_CLASSNAME);

    if(!link.href) {
      return;
    }

    const targetDomain = URI(link.href).hostname().toLowerCase();
    const isUrlSameDomain = window.location.hostname === targetDomain;

    // clear previous classnames
    const classes = [];

    if(isUrlSameDomain) {
      classes.push(INTERNAL_LINK_CLASSNAME);
    }
    else {
      classes.push(EXTERNAL_LINK_CLASSNAME);
    }

    if(link.firstChild && link.firstChild.nodeName === 'IMG') {
      classes.push(IMAGE_LINK_CLASSNAME);
    }

    link.classList.add(...classes);
  });

  return {
    textHtml: fragment.body.innerHTML,
    matchedFormulas
  };
};

/**
 * Re-number dynamic blocks based on the current number of blocks contained in the
 * modal editor.
 *
 * @param {string} [textHtml=''] html string containing editor blocks to process
 * @param {number} [startIndex=0] starting index value (integer)
 * @returns {string} the formatted html string with re-numbered dynamic blocks
 */
export const reindexDynamicBlocks = (textHtml = '', startIndex = 0) => {
  const fragment = new DOMParser().parseFromString(textHtml, 'text/html');
  const dynamicEls = fragment.querySelectorAll('.editor-formula[data-formula-index]');

  dynamicEls.forEach((node, index) => node.setAttribute('data-formula-index', index + startIndex));

  return fragment.body.innerHTML;
};

/**
 * Remove a specific dynamic block from editor HTML content by index.
 *
 * @param {string} [textHtml=''] html string containing dynamic blocks in edit mode
 * @param {number} [formulaIndex=0] index of the dynamic block to remove
 * @returns {string} the formatted html string after removing the specified dynamic block
 */
export const removeDynamicBlock = (textHtml = '', formulaIndex = 0) => {
  if(!textHtml) {
    return textHtml;
  }

  const fragment = new DOMParser().parseFromString(textHtml, 'text/html');
  const dynamicEl = fragment.querySelector(`.editor-formula[data-formula-index="${formulaIndex}"]`);

  dynamicEl && dynamicEl.remove();

  return reindexDynamicBlocks(fragment.body.innerHTML);
};

/**
 * Finds formulas codes in an html string and replaces them with DOM
 * elements with formula indexes
 *
 * @memberof CardEditModal
 * @param {string} textHtml html string.
 * @param {Array} formulas array of formula strings
 * @param {boolean} [editMode=false] set to true if in replacing formulas in card editor
 * @returns {string} the formatted html string
 */
export const replaceFormulasByDomElements = (textHtml = '', formulas = [], editMode = false) => {
  if(!formulas.length) {
    return textHtml;
  }

  let index = 0;

  return (textHtml || '').replace(FORMULA_REGEX, (m, content) => {
    if(!content) {
      return;
    }

    const formulaIndex = formulas.slice(0, ++index).lastIndexOf(content);
    const formulaName = getFormulaKey(formulas[formulaIndex]);

    // NOTE: DO NOT do this below: `<div></div>` --> `<div/>`. It will BREAK regEx...
    if(editMode) {
      // eslint-disable-next-line
      return `<div class="editor-formula fr-inner fr-deletable ${EDITOR_PLACEHOLDER_CLASS}" data-formula-index="${formulaIndex}" data-formula="${formulaName}" contenteditable="false"></div>`;
    }

    return `<div class="inline-formula" data-formula-index="${formulaIndex}" data-formula="${formulaName}"></div>`;
  });
};

/**
 * Finds formula codes in an html string and return them in an array format
 *
 * @memberof CardEditModal
 * @param {string} textHtml html string
 * @returns {Array} collection of formula codes
 */
export const extractHtmlFormulasToArray = (textHtml = '') => {
  const matches = [];
  let item;

  // see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec#Description
  while(item = FORMULA_REGEX.exec(textHtml)) {
    if(item && (item.length > 1)) {
      matches.push(item[1]);
    }
  }

  return matches;
};

/**
 * This function creates string content based on a scratchpad comment,
 * extracts attachments, and returns the body + attachments elements
 *
 * @memberof CardEditModal
 * @param {object} comment The second number.
 * @returns {string} The formated html string
 */
export const getContentFromScratchpadComment = comment => {
  const {bodyPlain: scratchpadBodyText, bodyHtml: scratchpadBodyHtml, attachments = []} = comment;

  if(!attachments.length) {
    return scratchpadBodyText || scratchpadBodyHtml;
  }

  const attachmentHtml = attachments.reduce((html, attachment) => {
    const {fileName, mimeType, type, url} = normalizeAttachment(attachment);

    switch(type) {
      case 'image':
        return `${html}<p editor-inline-attachment editor-inline-attachment_image><img alt="${fileName}" src="${url}" loading="lazy" /></p>`;
      default:
        const iconType = getFileType(mimeType);

        return `
            ${html}
            <p class="editor-inline-attachment editor-inline-attachment_file fr-deletable">
              <i class="svg-icon icon-attachment-${iconType}"></i>
              <a class="editor-inline-attachment_link" target="_blank" href="${url}" contenteditable="false" title="Click to download">
                ${fileName}
              </a>
            </p>
          `;
    }
  }, '');

  return `
    ${scratchpadBodyText}
    ${attachmentHtml}
  `;
};

// https://soapbox.github.io/linkifyjs/docs/
export const linkify = inputText => linkifyHtml(inputText);

export const attachments = {
  Company: {
    compareEmployeeCount: {newBoardDefault: false, label: 'Compare Employee Growth'},
    employeeCount: {newBoardDefault: true, label: 'Number of Employees'},
    officeLocations: {newBoardDefault: true, label: 'Employee Locations'}
  },
  People: {
    keyPeople: {newBoardDefault: true, label: 'Key People'},
    jobCategories: {newBoardDefault: false, label: 'Job Categories'},
    jobPostings: {newBoardDefault: false, label: 'Job Postings'},
    jobPostingsByRegion: {newBoardDefault: true, label: 'Job Postings by Region'}
  },
  Financials: {
    balanceSheetStatementsQuarterly: {newBoardDefault: false, label: 'Balance Sheet - Quarter'},
    balanceSheetStatements: {newBoardDefault: false, label: 'Balance Sheet - Year'},
    cashFlowStatementsQuarterly: {newBoardDefault: false, label: 'Cash Flow - Quarter'},
    cashFlowStatements: {newBoardDefault: false, label: 'Cash Flow - Year'},
    compareRevenues: {newBoardDefault: false, label: 'Compare Revenue Growth'},
    incomeStatementsQuarterly: {newBoardDefault: true, label: 'Income Statements - Quarter'},
    incomeStatements: {newBoardDefault: false, label: 'Income Statements - Year'},
    operatingMetricsSummary: {newBoardDefault: true, label: 'Operating Metrics'},
    revenues: {newBoardDefault: true, label: 'Revenue'}
  },
  Publications: {
    recentBlogPosts: {newBoardDefault: true, label: 'Recent Blog Posts'},
    recentCaseStudies: {newBoardDefault: false, label: 'Recent Case Studies'},
    recentPressReleases: {newBoardDefault: true, label: 'Recent Press Releases'},
    recentPublications: {newBoardDefault: false, label: 'Recent Publications'}
  },
  Salesforce: {
    sfdcLatestOpportunities: {newBoardDefault: false, label: 'Latest Opportunities', type: 'static'},
    sfdcWins: {newBoardDefault: false, label: 'Win Rate', type: 'static'}
  },
  Website: {
    engagementSummary: {newBoardDefault: false, label: 'Avg. Engagement per Visit'},
    homepageScreenshot: {newBoardDefault: false, label: 'Homepage Screenshot'},
    websiteTrafficOrganicKeywords: {newBoardDefault: true, label: 'Organic Search Keywords'},
    trafficPageViews: {newBoardDefault: false, label: 'Page Views per Visit'},
    websiteTrafficPaidKeywords: {newBoardDefault: true, label: 'Paid Search Keywords'},
    similarWebsites: {newBoardDefault: false, label: 'Similar Sites'},
    trafficSources: {newBoardDefault: true, label: 'Traffic Share by Type'}
  }
};

const htmlToTextConfig = {
  unorderedListItemPrefix: '',
  preserveNewlines: true,
  singleNewLineParagraphs: true,
  wordwrap: false,
  uppercaseHeadings: false,
  format: {
    heading(elem, fn, options) {
      return `<p>${fn(elem.children, options)}</p>`;
    },
    image(elem) {
      return `<img src="${elem.attribs.src}" ${
        elem.attribs.alt ? `alt="${elem.attribs.alt}"` : 'alt=""'
      } loading="lazy">`;
    },
    anchor(elem, fn, options) {
      const children = fn(elem.children, options);

      return `<a href="${elem.attribs.href}" target="_blank" rel="noopener">${children}</a>`;
    },
    lineBreak(elem, fn, options) {
      const parentHasOtherChildren = elem.parent ? elem.parent.children.length > 1 : false;

      return parentHasOtherChildren ? fn(elem.children, options) : '';
    }
  }
};

const elementsToAddBreakLines = ['P', 'DIV', 'IMG', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
const disabledList = ['IMG', 'HR'];
const editorReservedElements = [
  'editor-formula',
  'fr-video',
  'klue-embed'
];
const tableElements = ['TABLE', 'TR', 'TD', 'TBODY', 'THEAD'];
const shouldIgnoreParent = [
  //we also need to parser the parent element
  //sometimes the froala editor view is the parent so we just ignore in this case
  el => el.classList.contains('fr-view'),

  //video comes with a P tag as a parent, in this case we can't clean the parent
  //otherwise it will remove the video.
  //the only way to insert videos is using the editor function
  //so it is okay to leave the parent the way it is.
  el => el.querySelectorAll('.fr-video').length > 0,

  //embedded code comes with a P tag as a parent, in this case we can't clean the parent
  //otherwise it will remove the element.
  //the only way to insert embedded is using the editor function
  //so it is okay to leave the parent the way it is.
  el => el.querySelectorAll('.klue-embed').length > 0,

  //do not clean table elements
  el => tableElements.some(name => el.tagName === name),
  el => el.classList.contains('table-scrollwrap')
];

/**
 * @memberof parseListElements
 * @param {HTMLElement} listElement the list element
 * @param {string} rangePlainText the range selected text
 * @returns {boolean} is all the items from the list selected?
 */

function checkIsAllItemsSelected(listElement, rangePlainText) {
  let isAllItemsSelected = false;

  for(const item of listElement.children) {
    isAllItemsSelected = rangePlainText.includes(item.textContent);

    if(!isAllItemsSelected) { break; }
  }

  return isAllItemsSelected;
}

/**
 * @memberof semanticParser
 * @param {HTMLELement} el
 * @returns {boolean} return if the element should be ignored or not.
 */

function shouldIgnoreElement(el) {
  const isEditorReservedElement = editorReservedElements.some(className =>
    el.classList?.contains(className)
  );

  return disabledList.includes(el.tagName) || isEditorReservedElement;
}

/**
 * @memberof clearFormatting
 * @param {Arrray<HTMLElement, string>} item  a tuple of an HTMLElement and a new value
 *  where values represents a string or an HTML string
 */
const updateDomElement = ([el, value]) => {
  el.outerHTML = value;
};

/**
 * @memberof semanticParser
 * @params {string} value
 * @returns {string}
 */

const backToEditorDefault = value => {
  return value
    .split(/\n|\r/g)
    .map(it => {
      return it.includes('<p>') || _.isEmpty(it) ? it : `<p>${it}</p>`;
    })
    .join('');
};

/**
 * @memberof getSemanticParser
 * @param options an object
 * @params {Range} options.range
 * @params {HTMLElement} options.el
 * @params {htmlToText} options.htmlToText
 * @params {Array<Array<HTMLElement, any>>} option.lazyUpdates
 *  where values represents a string or an HTML string
 * @returns void
 */

const parseListElements = ({range, el, htmlToText, lazyUpdates}) => {
  const rangePlainText = range.toString();
  const isAllItemsSelected = checkIsAllItemsSelected(el, rangePlainText);

  if(isAllItemsSelected) {
    const clearedHtml = htmlToText(
      // there is a bug in ordered lists that kept the numbers
      // a easy fix is once we wanna clean all the list is just replace
      // ol -> ul so then the bug doesn't happens anymore.
      el.outerHTML.replace('ol', 'ul'),
      htmlToTextConfig
    );

    lazyUpdates.push([el, backToEditorDefault(clearedHtml)]);
  }
  else {
    // making sure that we will remove the list styles
    // of the item selected if not selected all items
    for(const item of el.children) {
      rangePlainText.includes(item.textContent) && lazyUpdates.push([item, `<li>${item.textContent}</li>`]);
    }
  }
};

/**
 * @memberof getSemanticParser
 * @param options an object
 * @params {Range} options.range
 * @params {HTMLElement} options.el
 * @params {htmlToText} options.htmlToText
 * @params {Array<Array<HTMLElement, any>>} option.lazyUpdates
 *  where values represents a string or an HTML string
 * @returns void
 */

const parseTableCell = ({el, range, htmlToText, lazyUpdates}) => {
  const rangePlainText = range.toString();

  for(const item of el.children) {
    if(rangePlainText.includes(item.textContent) && !shouldIgnoreElement(item)) {
      lazyUpdates.push([item, htmlToText(item.outerHTML, htmlToTextConfig)]);
    }
  }
};

/**
 * @memberof getSemanticParser
 * @params {HTMLElement} options.el
 * @returns {string}
 */

const cleanListItemHtml = ({el, htmlToText}) => {
  return `<li>${htmlToText(el.innerHTML, htmlToTextConfig)}</li>`;
};

/**
 * @memberof getSemanticParser
 * @params {HTMLElement} options.el
 * @returns {string}
 */

const cleanTableCellHtml = ({el, htmlToText}) => {
  return `<td style="${el.attributes?.style?.textContent}">
    ${htmlToText(el.innerHTML, htmlToTextConfig)}
  </td>`;
};

/**
 * @memberof getSemanticParser
 * @params {HTMLElement} options.el
 * @returns {string}
 */

const cleanTableHeaderCellHtml = ({el, htmlToText}) => {
  return `<th>
    ${htmlToText(el.innerHTML, htmlToTextConfig)}
  </th>`;
};

/**
 * @memberof getSemanticParser
 * @params {HTMLElement} options.el
 * @returns {string}
 */

const cleanLinkHtml = ({el, htmlToText}) => {
  return `<a href="${el.href}" target="_blank" rel="noopener">
    ${htmlToText(el.innerHTML, htmlToTextConfig)}
  </a>`;
};

/**
 * @memberof getSemanticParser
 * @params {HTMLElement} options.el
 * @params {Range} options.range
 * @returns {boolean}
 */

const isElementFullySelected = ({el, range}) => {
  const rangeDocumentFragment = range.cloneContents();
  const elementsInRange = Array.from(
    rangeDocumentFragment.querySelectorAll(el.tagName)
  );

  return elementsInRange.length
    ? elementsInRange.some(element => element.innerHTML === el.innerHTML)
    : range.toString().includes(el.textContent);
};

/**
 * @memberof clearFormatting
 * @param {Range} range
 * @returns {Function} a function that returns an array of dom updates to be made.
 */
const getSemanticParser = async range => {
  const lazyUpdates = []; // tuple of [[el, new value], [el, new value]]

  const parseByElementType = {
    UL: parseListElements,
    OL: parseListElements,
    TD: parseTableCell
  };

  const clearHtmlByElementType = {
    LI: cleanListItemHtml,
    TD: cleanTableCellHtml,
    TH: cleanTableHeaderCellHtml,
    A: cleanLinkHtml
  };

  return function semanticParser(el, {ignoreChildren = false}) {
    if(shouldIgnoreElement(el)) {
      return lazyUpdates;
    }

    if(el?.children?.length && !ignoreChildren) {
      const customElementParser = parseByElementType[el.tagName];

      if(customElementParser) {
        customElementParser({range, el, htmlToText, lazyUpdates});

        return lazyUpdates;
      }

      // if element has children but doesn't have an custom parser
      // we then parse each children separately
      // and later the parent as well
      Array.from(el.children).forEach(semanticParser);

      //depending on the case we should ignore the parent to avoid issues
      //check the shouldIgnoreParent to learn about the variants we should ignore
      if(!shouldIgnoreParent.some(checker => checker(el))) {
        return semanticParser(el, {ignoreChildren: true});
      }
    }
    else {
      //we found strange bug which cause just #text to arrive
      //if it is just text depending on how you select the content
      //we don't need to do nothing. to then we treat only the parent node
      const elementToParse = el.nodeName !== '#text' ? el : el.parentNode;

      if(elementToParse !== '#text') {
        // somehow the parent arrives without children sometimes so we
        // need to double check it at element level
        if(shouldIgnoreParent.some(checker => checker(elementToParse))) {
          return lazyUpdates;
        }

        // should only touch the element if it was fully selected
        // not the best solution yet
        if(!isElementFullySelected({el: elementToParse, range})) {
          return lazyUpdates;
        }
      }

      const clearedHtml =
        clearHtmlByElementType[elementToParse.tagName]?.({
          el: elementToParse,
          htmlToText
        }) ?? htmlToText(elementToParse.outerHTML, htmlToTextConfig);

      lazyUpdates.push([
        elementToParse,
        elementsToAddBreakLines.includes(elementToParse.tagName)
          ? backToEditorDefault(clearedHtml)
          : clearedHtml
      ]);
    }

    return lazyUpdates;
  };
};

/**
 * @param {Froala editor instance} editor
 * @returns {Record<Range, HTMLElement> || boolean}
 */

const getEditorSelectionData = editor => {
  const selection = editor.selection.get();

  // note about selection.type === 'Caret':
  // Just a workaround to avoid clearing format if there is no DOM selected (range)
  if(!selection || selection.type === 'Caret') {
    return false;
  }

  const range = selection && selection.getRangeAt(0);
  const isSingleElement = editor.selection.text() === selection.focusNode.wholeText;

  const element = isSingleElement
    ? editor.selection.element()
    : range.commonAncestorContainer;

  return {range, element};
};

/**
 * @param {Froala editor instance} editorComponent
 * @returns void
 */
export const clearFormatting = async editorComponent => {
  const {editor} = editorComponent;

  editor.format.removeStyle('font-size');
  editor.format.removeStyle('font-weight');
  editor.format.removeStyle('color');
  editor.format.removeStyle('text-align');
  editor.html.cleanEmptyTags();

  const editorSelectionData = getEditorSelectionData(editor);

  if(editorSelectionData) {
    const semanticParser = await getSemanticParser(editorSelectionData.range);

    semanticParser(editorSelectionData.element, {ignoreChildren: false})
      .forEach(updateDomElement);
  }
};

/**
 * @param {Froala editor instance} editorComponent
 * @returns void
 */

export const removeExtraSpacesUtil = editorComponent => {
  const {editor} = editorComponent;

  const editorSelectionData = getEditorSelectionData(editor);
  const lazyUpdates = [];

  if(editorSelectionData) {
    const breakingLines = editorSelectionData.element?.querySelectorAll
      ? Array.from(editorSelectionData.element.querySelectorAll('br'))
      : [];

    breakingLines.forEach(br => {
      const isNextElementBreakLine =
        br.nextSibling?.tagName === 'BR';

      if(isNextElementBreakLine) {
        lazyUpdates.push(() => br.remove());
      }
    });
  }

  lazyUpdates.forEach(removeBr => removeBr());
};

export const getEditorInstance = () => {
  FroalaEditor.DefineIconTemplate(
    'klue-link-icon',
    // eslint-disable-next-line max-len
    `<svg width="1200" height="1200" viewBox="0 0 1200 1200" fill="none" xmlns="http://www.w3.org/2000/svg">[path]
    </svg>`
  );

  FroalaEditor.DefineIcon('klue-card-link-icon', {
    template: 'klue-link-icon',
    name: 'klue-card-link-icon',
    // eslint-disable-next-line max-len
    path: '<path d="M488.051 244H392.004C387.031 244 383 248.029 383 253V946C383 950.971 387.031 955 392.004 955H488.051C493.024 955 497.056 950.971 497.056 946V253C497.056 248.029 493.024 244 488.051 244Z" fill="#011627"/><path d="M510.003 672.024C506.947 668.361 507.28 662.952 510.762 659.692L756.431 429.63C758.1 428.067 760.301 427.198 762.588 427.198H879.977C888.074 427.198 892.057 437.046 886.235 442.67L657.785 663.312C654.408 666.573 654.106 671.88 657.089 675.504L875.126 940.28C879.961 946.152 875.782 955 868.174 955H741.871C739.119 955 736.518 953.742 734.81 951.586L575.578 750.507C575.567 750.492 575.544 750.49 575.531 750.504C575.518 750.517 575.496 750.516 575.484 750.502L510.003 672.024Z" fill="#011627"/>'
  });

  FroalaEditor.DefineIconTemplate(
    'klue-editor-icon',
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="klue-editor-icon klue-editor-icon_[name]">[path]</svg>'
  );
  FroalaEditor.DefineIcon('klue-attachments', {
    template: 'klue-editor-icon',
    name: 'attachments',
    path: `<path d="M9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4zm2.5 2.1h-15V5h15v14.1zm0-16.1h-15c-1.1 0-2
      .9-2 2v14c0 1.1.9 2 2 2h15c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>`
  });
  FroalaEditor.DefineIcon('image-zoom-toggle', {
    template: 'klue-editor-icon',
    name: 'image-zoom-toggle',
    /* eslint-disable-next-line max-len */
    path: '<g class="off"><path d="m9.7559 3.2559c-3.59 0-6.5 2.91-6.5 6.5 0 3.59 2.91 6.5 6.5 6.5 1.61 0 3.0885-0.59031 4.2285-1.5703l0.27148 0.2793v0.79102l5 4.9883 1.4883-1.4883-4.9883-5h-0.79102l-0.2793-0.27148c0.83046-0.96605 1.3768-2.1773 1.5254-3.5039h-2.0273c-0.3472 2.1418-2.186 3.7754-4.4277 3.7754-2.49 0-4.5-2.01-4.5-4.5 0-2.49 2.01-4.5 4.5-4.5 0.83688 0 1.609 0.24071 2.2793 0.63672v-2.2188c-0.71045-0.26661-1.4757-0.41797-2.2793-0.41797z"/><path d="m12.255 10.255h-2v2h-1v-2h-2v-1h2v-2h1v2h2z"/><path d="m19.871 3.1698 0.94435 0.94431-4.4006 4.4067-2.8393-2.833 0.94435-0.94431 1.895 1.8886z"/></g><g class="on"><path d="m9.7559 3.2559c-3.59 0-6.5 2.91-6.5 6.5s2.91 6.5 6.5 6.5c1.61 0 3.0885-0.59031 4.2285-1.5703l0.27148 0.2793v0.79102l5 4.9883 1.4883-1.4883-4.9883-5h-0.79102l-0.2793-0.27148c0.83046-0.96605 1.3768-2.1773 1.5254-3.5039h-2.0273c-0.3472 2.1418-2.186 3.7754-4.4277 3.7754-2.49 0-4.5-2.01-4.5-4.5s2.01-4.5 4.5-4.5c0.83688 0 1.609 0.24071 2.2793 0.63672v-2.2188c-0.71045-0.26661-1.4757-0.41797-2.2793-0.41797z" opacity=".5"/><path d="m12.255 10.255h-2v2h-1v-2h-2v-1h2v-2h1v2h2z" opacity=".5"/><path d="m19.097 2.7284-2 2-2-2-1.0888 1.0888 2 2-2 2 1.0888 1.0888 2-2 2 2 1.0888-1.0888-2-2 2-2z"/></g>'
  });
  FroalaEditor.DefineIcon('image-display-inline-toggle', {
    template: 'klue-editor-icon',
    name: 'image-display-inline-toggle',
    /* eslint-disable-next-line max-len */
    path: '<g class="on"><path d="M3,5h18v2H3V5z M13,9h8v2h-8V9z M13,13h8v2h-8V13z M3,17h18v2H3V17z M3,9h8v6H3V9z"></path></g><g class="off"><path d="M3,5h18v2H3V5z M3,17h18v2H3V17z M3,9h8v6H3V9z"></path></g>'
  });
  FroalaEditor.DefineIcon('font-size--labelled', {
    NAME: 'text-height',
    SVG_KEY: 'fontSize'
  });
  FroalaEditor.DefineIcon('list-types', {
    NAME: 'list-ul',
    SVG_KEY: 'unorderedList'
  });
  FroalaEditor.DefineIcon('iframe', {
    NAME: 'iframe',
    SVG_KEY: 'codeView'
  });

  FroalaEditor.DefineIcon('expand-table', {
    name: 'expand-table',
    template: 'klue-editor-icon',
    // eslint-disable-next-line max-len
    path: `
      <g fill="none" fill-rule="evenodd">
        <g transform="translate(-1 5)">
          <path fill="#0E0E0E" d="M21.5 7l4 3.5v-7zM4.5 7l-4 3.5v-7z"/>
          <rect width="14" height="14" x="6" stroke="#000" rx="2"/>
        </g>
        <path stroke="#000" stroke-linecap="square" d="M5 8h13"/>
        <path fill="#000" d="M9 8h6v11H9z"/>
      </g>
      `
  });

  FroalaEditor.DefineIcon('fullScreen', {
    name: 'fullScreen',
    template: 'klue-editor-icon',
    path: `
        <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z" />
      `
  });

  return {
    FroalaEditorComponent,
    FroalaEditor
  };
};

const idToImageLookup = {};

export const handleImageBeforeRemove = img => {
  const identifier = img.attr('id');

  if(!idToImageLookup[identifier]) {
    return true; // an image that's not being uploaded is being deleted... all good.
  }

  const {onImageUploadStatus} = idToImageLookup[identifier];

  delete idToImageLookup[identifier];

  onImageUploadStatus({
    identifier,
    status: 'deleted'
  });

  return true;
};

const getInsertedImage = (editor, identifier) => {
  const elements = [...editor.el.querySelectorAll(`[id="${identifier}"]`)];

  return elements.length ? elements.pop() : null;
};

const handleS3UploadFailed = ({identifier, onImageUploadStatus, statusData, error, editor}) => {
  const image = getInsertedImage(editor, identifier);

  if(image) {
    image.src = imageErrorPlaceholder;
    delete idToImageLookup[identifier];

    return onImageUploadStatus && onImageUploadStatus({
      identifier,
      status: 'error',
      error,
      statusData
    });
  }
};

const handleS3Upload = ({url, identifier, editor, onImageUploadStatus, statusData, isLargerThanDigestWidth}) => {
  const image = getInsertedImage(editor, identifier);

  if(image) {
    image.src = url;

    // A constraint for wide images on outlook

    if(isLargerThanDigestWidth) {
      image.setAttribute('width', '500');
    }

    delete idToImageLookup[identifier];

    return onImageUploadStatus && onImageUploadStatus({
      identifier,
      status: 'uploaded',
      statusData
    });
  }

  handleS3UploadFailed({identifier});
};

export const handleImageUpload2 = ({editor, images, onImageUploadStatus, statusData}) => {
  const reader = new FileReader();
  const identifier = new Date().getTime();
  const image = images[0];
  const isFromClipboard = !('name' in image);
  let isLargerThanDigestWidth = false;

  if(image && image.size > (3 * 1024 * 1024)) {
    onImageUploadStatus && onImageUploadStatus({
      identifier,
      status: 'maxFileSizeExceeded',
      statusData
    });

    return false;
  }

  editor.popups.hideAll();

  // images pasted from clipboard may do not have a name key
  if(isFromClipboard) {
    image.name = '';
  }

  const img = editor.image.get();

  // clean up the last upload-failed class in case it exist
  img && img.removeClass('upload-failed');

  idToImageLookup[identifier] = {image, onImageUploadStatus, statusData, editor};

  reader.onload = ({target: {result}}) => {
    const uploadedImage = new Image();

    uploadedImage.src = reader.result;

    uploadedImage.onload = function() {
      isLargerThanDigestWidth = uploadedImage.width > 500 ? true : false;
      editor.image.insert(result, null, {id: identifier, loading: true}, img);
    };
  };

  reader.readAsDataURL(image);

  onImageUploadStatus && onImageUploadStatus({
    identifier,
    status: 'uploading',
    statusData
  });

  uploadFileToS3(image)
    .then(({signingObject}) => {
      if(!idToImageLookup[identifier]) {
        // the image may have been deleted by the user
        return;
      }

      handleS3Upload({url: signingObject.assetUrl, identifier, editor, onImageUploadStatus, statusData, isLargerThanDigestWidth});
    })
    .catch(error => {
      if(!idToImageLookup[identifier]) {
        // the image may have been deleted by the user
        return;
      }

      // if upload fails, display some info to user to try a different image
      handleS3UploadFailed({identifier, onImageUploadStatus, editor, statusData, error});
    });

  return false;
};

export const handleImageUpload = ({editor, images, onBusy, onImageUploadSuccess}) => {
  const reader = new FileReader();
  const tempName = new Date().getTime();
  const image = images[0];
  const isFromClipboard = !('name' in image);

  editor.popups.hideAll();

  // images pasted from clipboard may do not have a name key
  if(isFromClipboard) {
    image.name = '';
  }

  const img = editor.image.get();

  // clean up the last upload-failed class in case it exist
  img && img.removeClass('upload-failed');

  reader.onload = ({target: {result}}) => {
    editor.image.insert(result, null, {id: tempName, loading: true}, editor.image.get());
  };

  // - read image as base64
  // - display this temp image while the server uploads to s3
  reader.readAsDataURL(image);

  onBusy(true);

  // when the upload is done, replace the temp base64 by the uploaded url
  uploadFileToS3(image)
    .then(({signingObject}) => {
      const selector = getInsertedImage(editor, tempName);

      if(!selector) {
        return;
      }

      selector.src = signingObject.assetUrl;
      selector.classList.remove(EDITOR_IMG_PLACEHOLDER_CLASS);

      onImageUploadSuccess();
    })
    .catch(() => {
      // if upload fails, display some info to user to try a different image
      const selector = getInsertedImage(editor, tempName);

      if(!selector) {
        return;
      }

      selector.setAttribute('class', 'upload-failed');
    }).finally(() => {
      onBusy(false);
    });

  return false;
};

export const updateButtonGroupBasedOnFeatureFlags = (buttonGroup, _context) => {
  return buttonGroup.reduce((acc, button) => {
    if(button === 'insertLink') {
      acc.push('klue-card-link-selector');
    }
    else if(button === 'imageLink') {
      acc.push('klue-card-image-link-selector');
    }
    else {
      acc.push(button);
    }

    return acc;
  }, []);
};

export const updateToolbarButtonsBasedOnFeatureFlags = ({buttons, context}) => {
  return buttons.map(buttonGroup => updateButtonGroupBasedOnFeatureFlags(buttonGroup, context));
};

export const updateToolbarShortcutsBasedOnFeatureFlags = ({shortcuts, _context}) => {
  return shortcuts.map(shortcut => ((shortcut !== 'insertLink') ? shortcut : 'klue-card-link-selector'));
};

const getExcludedStyles = ({context}) => {
  const {utils: {isRestrictStylesOnPasteEnabled}} = context || {};

  if(isRestrictStylesOnPasteEnabled()) {
    return ['font-size', 'font-weight'];
  }

  return [];
};

export const getPasteAllowedStyle = ({context, defaultConfig}) => {
  if(!context) {
    return defaultConfig;
  }

  const {pasteAllowedStyleProps: pasteAllowedStyles} = defaultConfig || {};

  if(!pasteAllowedStyles) {
    return defaultConfig;
  }

  const {utils: {isRestrictStylesOnPasteEnabled}} = context || {};
  const excludeStyles = getExcludedStyles({context});
  const pasteAllowedStyleProps = pasteAllowedStyles.filter(style => !excludeStyles.includes(style));

  if(isRestrictStylesOnPasteEnabled()) {
    // if FF set, we also set wordAllowedStyleProps to the same value
    return {
      pasteAllowedStyleProps,
      wordAllowedStyleProps: [...pasteAllowedStyleProps]
    };
  }

  return {
    pasteAllowedStyleProps
  };
};

export const editorInsertKlueLink = ({card, battlecard, linkText, editor, rootUrl, targetImage}) => {
  editor?.selection?.restore();

  if(card) {
    const {id} = card;
    const cardURL =  `${rootUrl || ''}card/${id}`;

    if(targetImage) {
      handleInsertTargetImageURL({editor, targetImage, urlText: cardURL});
    }
    else {
      editor.link.insert(cardURL, linkText, {target: '_blank', rel: 'nofollow'});
    }
  }
  else if(battlecard) {
    const {id, profile: {id: profileId}} = battlecard;
    const battlecardURL =  `${rootUrl || ''}profile/${profileId}/battlecard/view/${id}`;

    if(targetImage) {
      handleInsertTargetImageURL({editor, targetImage, urlText: battlecardURL});
    }
    else {
      editor.link.insert(battlecardURL, linkText, {target: '_blank', rel: 'nofollow'});
    }
  }
};

export const getEditorColors = ({company}) => {
  const {companyData} = company || {};
  const {colors: {text, background} = {}} = companyData || {};
  const textColorWithRemove = text ? [...text, 'REMOVE'] : null;
  const backgroundColorWithRemove = background ? [...background, 'REMOVE'] : null;
  const colors = {
    colorsText: textColorWithRemove || editorTextColors,
    colorsBackground: backgroundColorWithRemove || textColorWithRemove || editorTextColors
  };

  return colors;
};

export const fontSizeHtmlString = () => {
  const options = {
    [editorFontSizes.SMALL]: 'Small',
    [editorFontSizes.MEDIUM]: 'Medium',
    [editorFontSizes.LARGE]: 'Large',
    [editorFontSizes.XLARGE]: 'Extra Large'
  };
  let c = '<ul class="fr-dropdown-list" role="presentation">';

  for(const [val, label] of Object.entries(options)) {
    c += `<li role="presentation">
            <a
              class="fr-command toolbar-fontsize--${label.toLowerCase().replace(' ', '-')}"
              tabIndex="-1"
              role="option"
              data-cmd="fontSizeLabelled"
              data-param1="${val}"
              title="${label}">
              ${label}
            </a>
          </li>`;
  }

  c += '</ul>';

  return c;
};

export const handleFontSizeChange = (val, editor) => {
  editor.format.applyStyle('font-size', val);

  editor.selection.blocks().forEach(element => {
    element.style.fontSize = val;
  });
};

export const handleFontSizeMenuRefresh = (dropdown, editor) => {
  const val = editor.selection.element().style.fontSize;
  const dropdownEl = dropdown && dropdown.length ? dropdown[0] : null;    // NOTE: btn & dropdown are jquery-wrapped elements

  if(dropdownEl) {
    const activeOption = dropdownEl.querySelector('.fr-command.fr-active');
    const selectedOption = dropdownEl.querySelector(`.fr-command[data-param1="${val}"]`);

    if(activeOption) {
      activeOption.classList.remove('fr-active');
      activeOption.setAttribute('aria-selected', false);
    }

    if(selectedOption) {
      selectedOption.classList.add('fr-active');
      selectedOption.setAttribute('aria-selected', true);
    }
  }
};

const handleCleanupOptions = (htmlString, options) => {
  const {removeContentEditable, removeBackgroundColor} = options || {};

  if(!htmlString || (!removeContentEditable && !removeBackgroundColor)) {
    return htmlString;
  }

  const parsed = new DOMParser().parseFromString(htmlString, 'text/html');

  if(removeContentEditable) {
    parsed.querySelectorAll('div[contenteditable]').forEach(el => el.removeAttribute('contenteditable'));
  }

  if(removeBackgroundColor) {
    parsed.querySelectorAll('[style*="background-color"]:not(span)').forEach(el => {
      el.style.removeProperty('background-color');
    });
  }

  return parsed?.body?.innerHTML ?? '';
};

export const handleBeforeCleanup = (htmlString, options) => {
  // Workaround to prevent editor crashing when pasting google sheets content
  // see: https://github.com/kluein/klue/issues/6133
  if(/<google-sheets-html-origin>/g.test(htmlString)) {
    const parsed = new DOMParser().parseFromString(htmlString, 'text/html');

    const table = parsed.querySelector('table');

    return handleCleanupOptions(table?.outerHTML ?? '', options);
  }

  return handleCleanupOptions(htmlString, options);
};

export function handleUrlLinked(link, editor) {
  const url = $(link).attr('href');

  if(url?.startsWith('//')) {
    const prefix = editor?.opts?.linkAutoPrefix || 'https://';
    const prefixedUrl = prefix + url.substring(2);

    $(link).attr('href', prefixedUrl);
  }
}

export function handleInsertTargetImageURL({editor, targetImage, urlText}) {
  const parentEl = targetImage.get(0).parentElement;
  const imgEl = parentEl.innerHTML;

  parentEl.innerHTML = `<a href="${urlText}" target="_blank" rel="noreferrer nofollow">${imgEl}</a>`;
  editor?.events.trigger('contentChanged');
}

export function handleCardImageLinkButtonState($btn, editor) {
  const {image} = editor;

  if(image) {
    if(image.get(0)?.parent()?.get(0)?.tagName === 'A') {
      $btn.addClass('fr-disabled');
    }
    else {
      $btn.removeClass('fr-disabled');
    }
  }
}

export function getEditorLinkInfo(editor) {
  const url = editor?.link?.get() || {href: ''};
  const urlHRef = url?.href || '';
  const urlText = url?.innerText || (editor?.selection?.text ? editor?.selection?.text() : '');

  return {urlHRef, urlText};
}

